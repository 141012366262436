import axios from 'axios';
import PropTypes from 'prop-types';

const axiosOrder = async (data) => {
  const {
    REACT_APP_MOONS_OS_API,
    REACT_APP_CHECKOUT_AUTH_USERNAME,
    REACT_APP_CHECKOUT_ENDPOINTS_PASSWORD,
  } = process.env;

  // const options = {
  //   auth: {
  //     username: REACT_APP_CHECKOUT_AUTH_USERNAME,
  //     password: REACT_APP_CHECKOUT_AUTH_PASSWORD,
  //   },
  // };

  const response = await axios.post(`${REACT_APP_MOONS_OS_API}/endpoint/free-orders`, data, {
    headers: {
      Authorization: `Basic ${btoa(
        `${REACT_APP_CHECKOUT_AUTH_USERNAME}:${REACT_APP_CHECKOUT_ENDPOINTS_PASSWORD}`,
      )} `,
      'Content-Type': 'application/json',
    },
  });

  return response;
};
axiosOrder.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string.isRequired }),
};

export default axiosOrder;
