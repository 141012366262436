/* eslint-disable */
'use strict';
import convertPrice from './convertPrice';

/**
 * Checks and calculates all discounts in a commercetools order
 * @param {Object} order - The commercetools order object
 * @returns {Object} Object containing discount information and amounts
 */
const checkOrderDiscounts = (order) => {
  const discountInfo = {
    hasDiscount: false,
    discountTypes: [],
    totalDiscount: 0,
    discountAmounts: {
      cartDiscounts: 0,
      lineItemDiscounts: 0,
      priceDiscounts: 0,
      promoCodeDiscounts: 0
    },
    details: []
  };

  if (order.cartDiscounts && order.cartDiscounts.length > 0) {
    discountInfo.hasDiscount = true;
    discountInfo.discountTypes.push('cartDiscount');

    order.cartDiscounts.forEach(discount => {
      const discountAmount = calculateCartDiscountAmount(order, discount);
      discountInfo.discountAmounts.cartDiscounts += discountAmount;

      discountInfo.details.push({
        type: 'cartDiscount',
        name: discount.name,
        discountId: discount.id,
        amount: discountAmount
      });
    });
  }

  if (order.lineItems) {
    order.lineItems.forEach(item => {
      if (item.price.discounted) {
        discountInfo.hasDiscount = true;
        discountInfo.discountTypes.push('priceDiscount');

        const originalAmount = item.price.value.centAmount * item.quantity;
        const discountedAmount = item.price.discounted.value.centAmount * item.quantity;
        const discountAmount = (originalAmount - discountedAmount) / 100;

        discountInfo.discountAmounts.priceDiscounts += discountAmount;

        discountInfo.details.push({
          type: 'priceDiscount',
          productId: item.productId,
          originalPrice: item.price.value.centAmount / 100,
          discountedPrice: item.price.discounted.value.centAmount / 100,
          quantity: item.quantity,
          totalDiscount: discountAmount
        });
      }

      // Check line item discounts
      if (item.discountedPricePerQuantity && item.discountedPricePerQuantity.length > 0) {
        discountInfo.hasDiscount = true;
        discountInfo.discountTypes.push('lineItemDiscount');

        item.discountedPricePerQuantity.forEach(discount => {
          const originalAmount = item.price.value.centAmount * discount.quantity;
          const discountedAmount = discount.discountedPrice.value.centAmount * discount.quantity;
          const discountAmount = (originalAmount - discountedAmount) / 100;

          discountInfo.discountAmounts.lineItemDiscounts += discountAmount;

          discountInfo.details.push({
            type: 'lineItemDiscount',
            productId: item.productId,
            quantity: discount.quantity,
            originalPrice: item.price.value.centAmount / 100,
            discountedPrice: discount.discountedPrice.value.centAmount / 100,
            totalDiscount: discountAmount
          });
        });
      }
    });
  }

  if (order.discountCodes && order.discountCodes.length > 0) {
    discountInfo.hasDiscount = true;
    discountInfo.discountTypes.push('promoCode');

    order.discountCodes.forEach(code => {
      const discountAmount = calculatePromoCodeDiscount(order, code);
      discountInfo.discountAmounts.promoCodeDiscounts += discountAmount;

      discountInfo.details.push({
        type: 'promoCode',
        code: code.discountCode.id,
        amount: discountAmount
      });
    });
  }

  discountInfo.discountTypes = [...new Set(discountInfo.discountTypes)];

  discountInfo.totalDiscount = Object.values(discountInfo.discountAmounts)
    .reduce((total, amount) => total + amount, 0);

  return discountInfo;
}

const calculateCartDiscountAmount = (order, discount) => {
  if (discount.target && discount.target.type === 'lineItems') {
    const affectedItems = order.lineItems.filter(item =>
      discount.target.predicate.includes(item.productId));

    return affectedItems.reduce((total, item) => {
      const originalPrice = item.price.value.centAmount * item.quantity;
      const discountedPrice = (item.price.discounted?.value.centAmount || originalPrice) * item.quantity;
      return total + ((originalPrice - discountedPrice) / 100);
    }, 0);
  }

  return 0;
}

const calculatePromoCodeDiscount = (order, code) => {
  if (code.discountCode.cartDiscounts) {
    return code.discountCode.cartDiscounts.reduce((total, discount) => {
      return total + calculateCartDiscountAmount(order, discount);
    }, 0);
  }

  return 0;
}


/**
 * Return total price of all carts
 *
 * @param {array} carts
 * @param {boolean} isToShow when is true the function return with commas the price
 * @param {array} order when is true the function return with commas the price
 * @returns {string} final price carts
 */
function totalPriceCarts(carts, isToShow, order = null) {
  let totalPrice = '';
  const { cartState } = carts[0];
  const discounts = checkOrderDiscounts(carts[0]);
  const { totalDiscount = 0 } = discounts;
  totalPrice = `${carts[0].totalPrice.centAmount / 100} ${totalPrice}`;

  if (totalPrice === 0) {
    return isToShow ? convertPrice(Number(totalPrice).toFixed(2)) : Number(totalPrice);
  }

  let custom = null;
  if (carts[0].custom) custom = carts[0].custom;
  if (order && order.custom) custom = order.custom;

  if (custom) {
    const { fields } = custom;
    if (fields) {
      const { amountToBePaid = null, applyRetention = false, billingAmount = null } = fields;
      if (totalPrice > amountToBePaid && cartState === 'Active') {
        if (amountToBePaid) {
          totalPrice = +amountToBePaid - totalDiscount;
        }
        if (applyRetention && billingAmount && billingAmount > 0) {
          totalPrice -= +billingAmount;
        }
      } else if (totalPrice > amountToBePaid) {
        totalPrice = +amountToBePaid;
      } else {
        return isToShow ? convertPrice(Number(totalPrice).toFixed(2)) : Number(totalPrice);
      }
    }
  }
  return isToShow ? convertPrice(Number(totalPrice).toFixed(2)) : Number(totalPrice);
}

export default totalPriceCarts;
